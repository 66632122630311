import { library, dom } from "@fortawesome/fontawesome-svg-core";

// You have to import individually because our bundler isn't very smart...
import { faArrowDown } from "@fortawesome/pro-solid-svg-icons/faArrowDown";
import { faArrowUp } from "@fortawesome/pro-solid-svg-icons/faArrowUp";
import { faBook } from "@fortawesome/pro-solid-svg-icons/faBook";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { faSquareEnvelope } from "@fortawesome/pro-solid-svg-icons/faSquareEnvelope";
import { faImage } from "@fortawesome/pro-solid-svg-icons/faImage";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons/faTimesCircle";
import { faUserCircle } from "@fortawesome/pro-solid-svg-icons/faUserCircle";
import { faPersonCirclePlus } from "@fortawesome/pro-solid-svg-icons/faPersonCirclePlus";
import { faNewspaper } from "@fortawesome/pro-solid-svg-icons/faNewspaper";

library.add([
    faArrowDown,
    faArrowUp,
    faBook,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faExclamationTriangle,
    faSquareEnvelope,
    faImage,
    faTimes,
    faTimesCircle,
    faUserCircle,
    faPersonCirclePlus,
    faNewspaper
]);

dom.watch();
