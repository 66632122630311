import React from "react";

class CookiePolicy extends React.Component {
    state = {
        classes: ""
    };

    getCookie = (cname) => {
        var name = cname + "=";

        var decodedCookie = decodeURIComponent(document.cookie);

        var ca = decodedCookie.split(";");

        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];

            while (c.charAt(0) == " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    setCookie = (cname, cvalue, exdays) => {
        var d = new Date();

        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();

        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    hide = () => {
        this.setState({classes: ""});
        let app = document.getElementById("app");

        if(app) {
            app.classList.remove("cookie-policy");
        }
        this.setCookie("allowsCookies", "true", 365);
    }

    componentDidMount() {
        let cookie = this.getCookie("allowsCookies");

        if(cookie !== "true") {
            this.setState({classes: "show"});
            let app = document.getElementById("app");

            if(app) {
                app.classList.add("cookie-policy");
            }
        }
    }

    render() {
        return (
            <div id="CookiePolicy" className={this.state.classes}>
                <div className="main-container">
                    <div className="cookie-content">
                        <div>We’ve updated our <a href="/privacy-policy">Privacy Policy</a> to make it clearer how we use your personal data.<br/>Please read our <a href="/cookie-policy">Cookie Policy</a> to learn how we use cookies to provide you with a better experience.</div>
                        <button onClick={this.hide}>I UNDERSTAND</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default CookiePolicy;