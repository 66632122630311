import React from "react";
import PropTypes from "prop-types";
import InlineSearch from "../InlineSearch/InlineSearch";
import $ from "jquery";

function stopBodyScrolling(bool) {
    if (bool === true) {
        document.body.addEventListener("touchmove", freezeVp, false);
    } else {
        document.body.removeEventListener("touchmove", freezeVp, false);
    }
}

const freezeVp = (e) => {
    e.preventDefault();
};

class HamburgerMenu extends React.Component {
    static propTypes = {
        menus: PropTypes.array,
        isSignInOn: PropTypes.bool,
        isSearchOn: PropTypes.bool,
        searchInNav: PropTypes.bool,
        showSubscribeBtn: PropTypes.bool,
        includeSubscribe: PropTypes.bool,
    };

    static defaultProps = {
        menus: [],
        isSignInOn: false,
        isSearchOn: false,
        searchInNav: false,
        showSubscribeBtn: true,
        includeSubscribe: false,
    };

    state = {
        open: false,
        menu: {},
    };

    createMarkup = (html) => {
        return {__html: html};
    };

    _toggleMenu = () => {
        if (!this.state.open) {
            $("html").addClass("fixed");
            $("#TopAd").addClass("closed");
            $("#MainHeader").addClass("mobile-open");
            stopBodyScrolling(true);
        } else {
            $("html").removeClass("fixed");
            $("#TopAd").removeClass("closed");
            $("#MainHeader").removeClass("mobile-open");
            stopBodyScrolling(false);
        }
        this.setState({open: !this.state.open});
    };

    _toggleMenuItem = (idx) => {
        let menu = {...this.state.menu};
        menu.children[idx].open = !menu.children[idx].open;
        this.setState({menu});
    };

    _getMenuItemContent = (child, idx, hasChildren) => {
        if (hasChildren) {
            return (
                <div
                    className="menu-item"
                    onClick={() => this._toggleMenuItem(idx)}
                    role="button"
                    tabIndex="0"
                    aria-expanded={child.open}
                    aria-controls={`submenu-${idx}`}
                    aria-haspopup="true">
                    {child.label}
                    <span
                        className={`arrow-icon ${child.open ? "open" : ""}`}
                        aria-hidden="true">
                        <i className="fal fa-chevron-right"></i>
                    </span>
                </div>
            );
        } else {
            return (
                <a
                    href={child.url}
                    dangerouslySetInnerHTML={this.createMarkup(child.label)}
                />
            );
        }
    };

    _displayMenuItems = (child, idx) => {
        let hasChildren = child.children && child.children.length > 0;

        return (
            <div key={idx} className={child.open ? "open" : "closed"}>
                <li className="menuItem">
                    {this._getMenuItemContent(child, idx, hasChildren)}
                </li>
                {hasChildren && (
                    <ul
                        id={`submenu-${idx}`}
                        className="subMenuItems"
                        aria-labelledby={`menuitem-${idx}`}>
                        {child.children.map((childOfChild, index) => (
                            <li className="subMenuItem" key={index}>
                                <a
                                    href={childOfChild.url}
                                    dangerouslySetInnerHTML={this.createMarkup(
                                        childOfChild.label
                                    )}
                                />
                            </li>
                        ))}
                        <li className="subMenuItem">
                            <a href={child.url}>
                                {child.label === "More"
                                    ? "All"
                                    : `All ${child.label}`}
                            </a>
                        </li>
                    </ul>
                )}
            </div>
        );
    };

    _displayMenu = () => {
        let hasChildren =
            this.state.menu.children && this.state.menu.children.length > 0;

        return (
            <div className="menu-list-wrapper">
                <ul className="menuItems">
                    {this.props.isSignInOn && (
                        <li className="font-secondary text-primary subMenuItem">
                            <a href="/sign-in">Sign In</a>
                        </li>
                    )}
                    {this.props.isSignInOn && (
                        <li className="font-secondary text-primary subMenuItem">
                            <a href="/register">Register</a>
                        </li>
                    )}
                    {this.props.showSubscribeBtn && !this.props.searchInNav && (
                        <li className="font-secondary text-primary subMenuItem mobile-subscribe">
                            <a href="/subscribe">Subscribe</a>
                        </li>
                    )}
                    {hasChildren &&
                        this.state.menu.children.map((child, idx) =>
                            this._displayMenuItems(child, idx)
                        )}
                    {this.props.showSubscribeBtn && this.props.searchInNav && (
                        <li className="font-secondary text-primary subMenuItem mobile-subscribe text-center">
                            <a href="/subscribe">Subscribe Today!</a>
                        </li>
                    )}
                </ul>
            </div>
        );
    };

    componentDidMount() {
        let menus = [...this.props.menus];
        let menu = menus.find((m) => m.label === "Main Navigation") || {};
        if (menu.children) {
            for (let i = 0; i < menu.children.length; i++) {
                menu.children[i].open = false;
            }
        }
        this.setState({menu});
    }

    render() {
        return (
            <>
                <div className="InlineSearch MobileSearch">
                    <InlineSearch isSearchOn={this.props.isSearchOn} />
                </div>
                {this.props.includeSubscribe && (
                    <a className="no-hover-underline" href="/subscribe">
                        <button className="action-btn subscribe-btn">
                            Subscribe
                        </button>
                    </a>
                )}
                <div className={this.state.open ? "menu open" : "menu"}>
                    <div
                        className="btn menu-button open-btn"
                        title="Open Menu"
                        onClick={this._toggleMenu}
                        aria-label="Open Menu"
                        role="button"
                        tabIndex="0">
                        <i className="far fa-bars" aria-hidden="true" />
                    </div>
                    <div className="menu-list">
                        <div className="header">
                            <a id="menu-brand" href="/" aria-label="Home"></a>
                            <div
                                className="menu-button close-btn"
                                title="Close Menu"
                                onClick={this._toggleMenu}
                                aria-label="Close Menu"
                                role="button"
                                tabIndex="0">
                                <i
                                    className="far fa-times"
                                    aria-hidden="true"
                                />
                            </div>
                            {this.props.searchInNav && (
                                <InlineSearch
                                    classes="menu-search"
                                    isSearchOn={true}
                                    open={true}
                                    static={true}
                                />
                            )}
                        </div>
                        {this._displayMenu()}
                    </div>
                </div>
            </>
        );
    }
}

export default HamburgerMenu;
