/*
* Comments about React components code split:
*
* This file contains 5 components that are used on most pages of LM/CLM/DDN. It is bundled by base:js job in assets.config.js
* Other components are only used on a few pages or not shared by 3 sites. They have their own Index.jsx file and bundle. Check the components array in assets.config.js for more information
*
* If you are creating a new component that is shared on all/most pages for LM/CLM/DDN, please include it here
* If you are creating a new component that is used on only a few pages, please create a new Index.jsx file in its folder and update the components array in assets.config.js
*
* You may want to create a new job for a specific page if that page has many extra components that aren't the below 5. Refer to articleViewPage for an example
*
* You may also want to split this file into 3 for LM/CLM/DDN in the future. If this happens, base:js job needs to be updated as well.
* The reason why LM/CLM/DDN share this file right now is that they are all using the following 5 components on most of their pages.
* For example, if a new ComponentA is created for all pages only on LM, a new ComponentB is created for all pages only on CLM, you may want to create an LM_Index.jsx with the following code
* plus ComponentA, a CLM_Index.jsx with the following code plus ComponentB, and change this file to DDN_Index.jsx
*
* This wiki page would also be helpful if you want to get a list of all routes/views of LM/CLM/DDN
* https://gitlab.labx.com/docs/frontend/-/wikis/LM-CLM-DDN-Web-Pages
*/

import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
const { register } = require("@lpm/pug-components/dist/register");

// SSR components
import CookiePolicy from "./CookiePolicy/CookiePolicy";
import HamburgerMenu from "./HamburgerMenu/HamburgerMenu";
import InlineSearch from "./InlineSearch/InlineSearch";
import Pagination from "./Pagination/Pagination";

// React SSR
register("CookiePolicy/CookiePolicy", CookiePolicy);
register("HamburgerMenu/HamburgerMenu", HamburgerMenu);
register("InlineSearch/InlineSearch", InlineSearch);
register("Pagination/Pagination", Pagination);

// Not react
const currentIssue = document.getElementById("CurrentIssue");
const articleGroups = document.getElementsByClassName("ArticleGroup");
const articleTabLists = document.getElementsByClassName("ArticleTabList");
const featuredArticles = document.getElementsByClassName("FeaturedArticleList");

// Not react
if(currentIssue) {
    axios.get("/api/current-issue").then((response) => {
        currentIssue.innerHTML = response.data;
    });
}

if(articleGroups) {
    for(let i = 0; i<articleGroups.length; i++) {
        let ad = articleGroups[i].dataset.ad ? articleGroups[i].dataset.ad : "";

        let params = {
            title: articleGroups[i].dataset.title ? articleGroups[i].dataset.title : "",
            slugs: articleGroups[i].dataset.slugs ? articleGroups[i].dataset.slugs : "",
            exclude: articleGroups[i].dataset.exclude ? articleGroups[i].dataset.exclude : "",
            ad
        };

        axios.get("/api/article-group", {
            params
        }).then((response) => {
            articleGroups[i].innerHTML = response.data;
            if(ad) {
                //init ad if one is being loaded
                let adSlots = articleGroups[i].querySelectorAll("[data-zone]");
                let loadNewAdsEvent = new CustomEvent("loadNewAds", {detail: adSlots});

                window.dispatchEvent(loadNewAdsEvent);
            }
        });
    }
}

if(articleTabLists) {
    for(let i = 0; i<articleTabLists.length; i++) {
        let params = {};
        if(articleTabLists[i].dataset.config) {
            params = JSON.parse(articleTabLists[i].dataset.config);

            params.id = articleTabLists[i].dataset.id ? articleTabLists[i].dataset.id : "";
            params.ad = articleTabLists[i].dataset.ad ? articleTabLists[i].dataset.ad : "";
            params.category = articleTabLists[i].dataset.category ? articleTabLists[i].dataset.category : "";
            params.tag = articleTabLists[i].dataset.tag ? articleTabLists[i].dataset.tag : "";

            if(params.slugs) {
                params.slugs = params.slugs.join(",");
            }
            if(params.contentTypes) {
                params.contentTypes = params.contentTypes.join(",");
            }
            if(params.magazineSections) {
                params.magazineSections = params.magazineSections.join(",");
            }
        }

        axios.get("/api/article-tab-list", {
            params
        }).then((response) => {
            articleTabLists[i].innerHTML = response.data;
            if(params.ad) {
                let adSlots = articleTabLists[i].querySelectorAll("[data-zone]");

                adSlots.forEach(function(slot) {
                    if(params.adTargetId && params.adTargetName){
                        slot.classList.remove("unamedAd");
                        slot.classList.add(`${params.adTargetName}-ad`);
                        slot.setAttribute("data-adtargetvalue", +params.adTargetId);
                    }
                });
                let loadNewAdsEvent = new CustomEvent("loadNewAds", {detail: adSlots});

                window.dispatchEvent(loadNewAdsEvent);
            }
        });
    }
}

if(featuredArticles) {
    for(let i = 0; i<featuredArticles.length; i++) {
        let params = {};

        let queryKey = "";
        let queryValue = "";

        if(featuredArticles[i].dataset.category) {
            queryKey = `categoryno`;
            queryValue = featuredArticles[i].dataset.category;
        }
        if(featuredArticles[i].dataset.contenttype) {
            queryKey = `contentType`;
            queryValue = featuredArticles[i].dataset.contenttype;
        }
        if(featuredArticles[i].dataset.magazinesection) {
            queryKey = `magazineIssueSectionNo`;
            queryValue = featuredArticles[i].dataset.magazinesection;
        }

        params.queryKey = queryKey;
        params.queryValue = queryValue;
        params.offset = featuredArticles[i].dataset.offset ? featuredArticles[i].dataset.offset : 0;
        params.numArticles = featuredArticles[i].dataset.number ? featuredArticles[i].dataset.number : 3; //default to 3 articles

        axios.get("/api/featured-articles", {
            params
        }).then((response) => {
            featuredArticles[i].innerHTML = response.data;
        });
    }
}
