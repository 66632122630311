import { library, dom } from "@fortawesome/fontawesome-svg-core";
import "./light";
import "./solid";
import "./brands";

// You have to import individually because our bundler isn't very smart...
import { faAngleDoubleLeft } from "@fortawesome/pro-regular-svg-icons/faAngleDoubleLeft";
import { faAngleDoubleRight } from "@fortawesome/pro-regular-svg-icons/faAngleDoubleRight";
import { faAngleLeft } from "@fortawesome/pro-regular-svg-icons/faAngleLeft";
import { faAngleRight} from "@fortawesome/pro-regular-svg-icons/faAngleRight";
import { faAsterisk} from "@fortawesome/pro-regular-svg-icons/faAsterisk";
import { faBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faBook } from "@fortawesome/pro-regular-svg-icons/faBook";
import { faCabinetFiling } from "@fortawesome/pro-regular-svg-icons/faCabinetFiling";
import { faChartBar } from "@fortawesome/pro-regular-svg-icons/faChartBar";
import { faChevronSquareLeft } from "@fortawesome/pro-regular-svg-icons/faChevronSquareLeft";
import { faChevronSquareRight } from "@fortawesome/pro-regular-svg-icons/faChevronSquareRight";
import { faCog } from "@fortawesome/pro-regular-svg-icons/faCog";
import { faCommentAlt } from "@fortawesome/pro-regular-svg-icons/faCommentAlt";
import { faEdit } from "@fortawesome/pro-regular-svg-icons/faEdit";
import { faEye } from "@fortawesome/pro-regular-svg-icons/faEye";
import { faImage } from "@fortawesome/pro-regular-svg-icons/faImage";
import { faKey } from "@fortawesome/pro-regular-svg-icons/faKey";
import { faFile } from "@fortawesome/pro-regular-svg-icons/faFile";
import { faFileAlt } from "@fortawesome/pro-regular-svg-icons/faFileAlt";
import { faFileImage } from "@fortawesome/pro-regular-svg-icons/faFileImage";
import { faFilePdf } from "@fortawesome/pro-regular-svg-icons/faFilePdf";
import { faFlask } from "@fortawesome/pro-regular-svg-icons/faFlask";
import { faMailbox } from "@fortawesome/pro-regular-svg-icons/faMailbox";
import { faMicrophone } from "@fortawesome/pro-regular-svg-icons/faMicrophone";
import { faNewspaper } from "@fortawesome/pro-regular-svg-icons/faNewspaper";
import { faPhotoVideo } from "@fortawesome/pro-regular-svg-icons/faPhotoVideo";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons/faQuestionCircle";
import { faSatelliteDish } from "@fortawesome/pro-regular-svg-icons/faSatelliteDish";
import { faSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { faSignOut } from "@fortawesome/pro-regular-svg-icons/faSignOut";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons/faSpinnerThird";
import { faStar } from "@fortawesome/pro-regular-svg-icons/faStar";
import { faStoreAlt } from "@fortawesome/pro-regular-svg-icons/faStoreAlt";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faUser } from "@fortawesome/pro-regular-svg-icons/faUser";
import { faVideo } from "@fortawesome/pro-regular-svg-icons/faVideo";
import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons/faCalendarAlt";
import { faHeadphones } from "@fortawesome/pro-regular-svg-icons/faHeadphones";

library.add([
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleRight,
    faAsterisk,
    faBars,
    faBook,
    faCabinetFiling,
    faChartBar,
    faChevronSquareLeft,
    faChevronSquareRight,
    faCog,
    faCommentAlt,
    faEdit,
    faEye,
    faImage,
    faKey,
    faFile,
    faFileAlt,
    faFileImage,
    faFilePdf,
    faFlask,
    faMailbox,
    faMicrophone,
    faNewspaper,
    faPhotoVideo,
    faQuestionCircle,
    faSatelliteDish,
    faSearch,
    faSignOut,
    faSpinnerThird,
    faStar,
    faStoreAlt,
    faTimes,
    faUser,
    faVideo,
    faCalendarAlt,
    faHeadphones
]);

dom.watch();